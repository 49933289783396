import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const payrollModalRoutes: Routes = [
  {
    path: 'organisation/edit',
    outlet: 'modal',
    loadChildren: () =>
      import('./security/organisations/organisation-hub/organisation-edit/organisation-edit.module').then((m) => m.OrganisationEditModule)
  },
  {
    path: 'organisation/create',
    outlet: 'modal',
    loadChildren: () =>
      import('./security/organisations/organisation-hub/organisation-edit/organisation-edit.module').then((m) => m.OrganisationEditModule)
  },
  {
    path: 'payslips',
    outlet: 'right',
    loadChildren: () => import('./payrolls/payroll-payslip/payroll-payslip.module').then((m) => m.PayrollPayslipModule)
  },
  {
    path: 'payslips',
    outlet: 'right',
    loadChildren: () => import('./access-control/groups/group-hub/group-payslip/group-payslip.module').then((m) => m.GroupPayslipModule)
  },
  {
    path: 'pay-summary',
    outlet: 'right',
    loadChildren: () => import('./payrolls/payroll-pay-summary/payroll-pay-summary.module').then((m) => m.PayrollPaySummaryModule)
  },
  {
    path: 'pay-summary',
    outlet: 'right',
    loadChildren: () =>
      import('./access-control/groups/group-hub/group-pay-summary/group-pay-summary.module').then((m) => m.GroupPaySummaryModule)
  },
  {
    path: 'employee-details',
    outlet: 'modal',
    loadChildren: () =>
      import('./access-control/groups/group-hub/group-employee-details/group-employee-details.module').then(
        (m) => m.GroupEmployeeDetailsModule
      )
  },
  {
    path: 'employee-details',
    outlet: 'modal',
    loadChildren: () =>
      import('./payrolls/payroll-employee-details/payroll-employee-details.module').then((m) => m.PayrollEmployeeDetailsModule)
  },
  {
    path: 'employee-edit',
    outlet: 'secondaryModal',
    loadChildren: () =>
      import('./employees/employee-details/employee-edit-section/employee-edit-section-routing.module').then(
        (m) => m.EmployeeEditSectionRoutingModule
      )
  },
  {
    path: 'employee/holiday-entitlement-details',
    outlet: 'tertiaryModal',
    loadChildren: () =>
      import('./employees/employee-details/holiday-entitlement-routing.module').then((m) => m.HolidayEntitlementRoutingModule)
  },
  {
    path: 'employee/work-pattern',
    outlet: 'tertiaryModal',
    loadChildren: () => import('./employees/employee-details/employee-work-pattern.module').then((m) => m.EmployeeWorkPatternModule)
  },
  {
    path: 'employee/salary-details-old',
    outlet: 'tertiaryModal',
    loadChildren: () => import('./employees/employee-details/employee-salary-details.module').then((m) => m.EmployeeSalaryDetailsModule)
  },
  {
    path: 'employee/salary-details',
    outlet: 'tertiaryModal',
    loadChildren: () => import('./employees/employee-details/employee-salary-details.module').then((m) => m.EmployeeSalaryDetailsModule)
  },
  {
    path: 'employee/dynamic-costing-details',
    outlet: 'secondaryModal',
    loadChildren: () => import('./employees/employee-details/employee-costing-details.module').then((m) => m.EmployeeCostingDetailsModule)
  },
  {
    path: 'employee/rate-details',
    outlet: 'tertiaryModal',
    loadChildren: () => import('./employees/employee-details/employee-rate-details.module').then((m) => m.EmployeeRateDetailsModule)
  },
  {
    path: 'employee/position-details',
    outlet: 'tertiaryModal',
    loadChildren: () => import('./employees/employee-details/employee-position-details.module').then((m) => m.EmployeePositionDetailsModule)
  },
  {
    path: 'employee/inactive-position-details',
    outlet: 'secondaryModal',
    loadChildren: () =>
      import('./employees/employee-details/employee-inactive-position-details.module').then((m) => m.EmployeeInactivePositionDetailsModule)
  },

  {
    path: 'employee/spine-point-details',
    outlet: 'tertiaryModal',
    loadChildren: () =>
      import('./employees/employee-details/employee-spine-point-details.module').then((m) => m.EmployeeSpinePointDetailsModule)
  },
  {
    path: 'employees',
    outlet: 'modal',
    loadChildren: () => import('./employees/new-starters/new-starters.module').then((m) => m.NewStartersModule)
  },
  {
    path: 'group/set-managers',
    outlet: 'modal',
    loadChildren: () => import('./access-control/groups/group-hub/set-managers-dialog/set-managers.module').then((m) => m.SetManagersModule)
  },
  {
    path: 'group/set-headings',
    outlet: 'modal',
    loadChildren: () =>
      import('./access-control/groups/group-hub/set-group-heading-sets-dialog/set-group-heading-sets.module').then(
        (m) => m.SetGroupHeadingSetsModule
      )
  },
  {
    path: 'group/activate-group',
    outlet: 'modal',
    loadChildren: () =>
      import('./access-control/groups/groups-hub/activate-group-dialog/activate-group.module').then((m) => m.ActivateGroupModule)
  },
  {
    path: 'group/delete-group',
    outlet: 'modal',
    loadChildren: () =>
      import('./access-control/groups/groups-hub/delete-group-dialog/delete-group.module').then((m) => m.DeleteGroupModule)
  },
  {
    path: 'group/new-starter-wizard',
    outlet: 'modal',
    loadChildren: () =>
      import('./access-control/groups/group-hub/new-starter/group-new-starters.module').then((m) => m.GroupNewStartersModule)
  },
  {
    path: 'group/imports/one-off-payments',
    outlet: 'modal',
    loadChildren: () =>
      import('./access-control/groups/group-hub/one-off-payment-import/group-one-off-payments-import.module').then(
        (m) => m.GroupOneOffPaymentsImportModule
      )
  },
  {
    path: 'group/one-off-payments',
    outlet: 'modal',
    loadChildren: () =>
      import('./access-control/groups/group-hub/one-off-payments-modal/group-one-off-payments-modal.module').then(
        (m) => m.GroupOneOffPaymentsModalModule
      )
  },
  {
    path: 'group/one-off-payments-edit',
    outlet: 'modal',
    loadChildren: () =>
      import('./access-control/groups/group-hub/one-off-payments-edit-modal/group-one-off-payments-edit-modal.module').then(
        (m) => m.GroupOneOffPaymentsEditModalModule
      )
  },
  {
    path: 'group/one-off-deductions-edit',
    outlet: 'modal',
    loadChildren: () =>
      import('./access-control/groups/group-hub/one-off-deductions-edit-modal/group-one-off-deductions-edit-modal.module').then(
        (m) => m.GroupOneOffDeductionsEditModalModule
      )
  },
  {
    path: 'heading-set',
    outlet: 'modal',
    loadChildren: () => import('./access-control/heading-sets/modals/heading-sets-modals.module').then((m) => m.HeadingSetsModalsModule)
  },
  {
    path: 'payroll/one-off-payments',
    outlet: 'modal',
    loadChildren: () =>
      import('./payrolls/payroll-hub/one-off-payments-modal/payroll-one-off-payments-modal.module').then(
        (m) => m.PayrollOneOffPaymentsModalModule
      )
  },
  {
    path: 'payroll/one-off-payments-edit',
    outlet: 'modal',
    loadChildren: () =>
      import('./payrolls/payroll-hub/one-off-payments-edit-modal/payroll-one-off-payments-edit-modal.module').then(
        (m) => m.PayrollOneOffPaymentsEditModalModule
      )
  },
  {
    path: 'payroll/one-off-deductions-edit',
    outlet: 'modal',
    loadChildren: () =>
      import('./payrolls/payroll-hub/one-off-deductions-edit-modal/payroll-one-off-deductions-edit-modal.module').then(
        (m) => m.PayrollOneOffDeductionsEditModalModule
      )
  },
  {
    path: 'payroll/imports/one-off-payments',
    outlet: 'modal',
    loadChildren: () =>
      import('./payrolls/payroll-one-off-payments/payroll-one-off-payments.module').then((m) => m.PayrollOneOffPaymentsModule)
  },
  {
    path: 'payroll/imports/salary-and-allowances',
    outlet: 'modal',
    loadChildren: () =>
      import('./payrolls/payroll-salary-and-allowances/payroll-salary-and-allowances.module').then(
        (m) => m.PayrollSalaryAndAllowancesModule
      )
  },
  {
    path: 'payroll/:payrollId/group-handover-statuses',
    outlet: 'modal',
    loadChildren: () =>
      import('./payrolls/payroll-handover/group-handover-statuses-dialog/group-handover-statuses.module').then(
        (m) => m.GroupHandoverStatusesModule
      )
  },
  {
    path: 'payroll/:payrollId/advanced-reports',
    outlet: 'modal',
    loadChildren: () =>
      import('./payrolls/payroll-advanced-reports/payroll-advanced-reports.module').then((m) => m.PayrollAdvancedReportsModule)
  },
  {
    path: 'group/:groupId/payroll/:payrollId/advanced-reports',
    outlet: 'modal',
    loadChildren: () =>
      import('./access-control/groups/group-advanced-reports/group-advanced-reports.module').then((m) => m.GroupAdvancedReportsModule)
  },
  {
    path: 'advanced-view',
    outlet: 'modal',
    loadChildren: () =>
      import('./access-control/groups/groups-hub/groups-dashboard/advanced-views/groups-dashboard-advanced-views.module').then(
        (m) => m.GroupsDashboardAdvancedViewsModule
      )
  },
  {
    path: 'payroll-locked',
    outlet: 'message',
    loadChildren: () => import('./payrolls/payroll-locked/payroll-locked.module').then((m) => m.PayrollLockedModule)
  },
  {
    path: 'mass-invite',
    outlet: 'modal',
    loadChildren: () =>
      import('./security/users/users-hub/invitations/uninvited/mass-invite-modal/mass-invite-modal.module').then(
        (m) => m.MassInviteModalModule
      )
  },
  {
    path: 'employee-invitation-details',
    outlet: 'modal',
    loadChildren: () =>
      import(
        './security/users/users-hub/invitations/invites/employee-invitation-details-dialog/employee-invitation-details-dialog.module'
      ).then((m) => m.EmployeeInvitationDetailsDialogModule)
  },
  {
    path: 'employee-account-details',
    outlet: 'modal',
    loadChildren: () =>
      import('./security/users/users-hub/users-grid/employee-account-details-modal/employee-account-details-modal.module').then(
        (m) => m.EmployeeAccountDetailsModalModule
      )
  },
  {
    path: ':approverId/subordinates',
    outlet: 'tertiaryModal',
    loadChildren: () =>
      import('./security/users/users-hub/holiday-approvals/subordinates/subordinates-dialog.module').then((m) => m.SubordinatesDialogModule)
  },
  {
    path: 'grant-payroll-access',
    outlet: 'secondaryModal',
    loadChildren: () =>
      import(
        './security/users/users-hub/users-grid/employee-account-details-modal/grant-payroll-access-modal/grant-payroll-access-modal.module'
      ).then((m) => m.GrantPayrollAccessModalModule)
  },
  {
    path: 'positions',
    outlet: 'modal',
    loadChildren: () => import('./positions/positions-modal-routing-module').then((m) => m.PositionsModalRoutingModule)
  },
  {
    path: 'dynamic-report',
    outlet: 'modal',
    loadChildren: () =>
      import('./advanced-reports/dynamic-reporting/dynamic-reporting-modal-routing.module').then(
        (m) => m.DynamicReportingModalRoutingModule
      )
  },
  {
    path: 'delete-report',
    outlet: 'modal',
    loadChildren: () =>
      import('./advanced-reports/dynamic-reporting/delete-report-definition-dialog/delete-report-definition.module').then(
        (m) => m.DeleteReportDefinitionModule
      )
  },
  {
    path: 'bureau-profile',
    outlet: 'modal',
    loadChildren: () => import('./access-control/bureaus/modals/bureau-profiles-modals.module').then((m) => m.BureauProfilesModalsModule)
  },
  {
    path: 'test-form',
    outlet: 'modal',
    loadChildren: () => import('./design-system/snapshot-test-forms/test-form-modals.module').then((m) => m.TestFormModalsModule)
  },
  {
    path: 'payment-runs',
    outlet: 'modal',
    loadChildren: () => import('@app/payrolls/payment-runs/payments-modal/payment-runs-modal.module').then((m) => m.PaymentRunsModalModule)
  },
  {
    path: 'payroll-approval',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/payrolls/payment-runs/payroll-approval/approval-modal/payroll-approval-modal.module').then(
        (m) => m.PayrollApprovalModalModule
      )
  },
  {
    path: 'payroll-approval-confirmation',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/payrolls/payment-runs/payroll-approval/approval-confirmation-modal/payroll-approval-confirmation-modal.module').then(
        (m) => m.PayrollApprovalConfirmationModalModule
      )
  },
  {
    path: 'payment-authorisation',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/payrolls/payment-runs/payment-authorisation/authorisation-modal/payment-authorisation-modal.module').then(
        (m) => m.PaymentAuthorisationModalModule
      )
  },
  {
    path: 'change-details',
    outlet: 'modal',
    loadChildren: () => import('@app/security/users/change-details/change-details-modal.module').then((m) => m.ChangeDetailsModalModule)
  },
  {
    path: 'change-password',
    outlet: 'modal',
    loadChildren: () => import('@app/security/users/change-password/change-password-modal.module').then((m) => m.ChangePasswordModalModule)
  }
];
