import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { fetchAuthSession } from 'aws-amplify/auth';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ActiveSecurityContextStateService } from '../active-security/active-security-context.state-service';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationState: ActiveAuthenticationStateService,
    private securityStateService: ActiveSecurityContextStateService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authenticationState.isAuthenticated) return next.handle(request);
    if (request.url.indexOf(environment.apiRoot) === -1 && request.url.indexOf(environment.employeeHubApiRoot) === -1)
      return next.handle(request);

    return from(fetchAuthSession()).pipe(
      mergeMap((session) => {
        const jwt = session.tokens?.idToken?.toString();
        if (jwt?.length) {
          const jwtRequest = request.clone({
            withCredentials: false,
            setHeaders: {
              Authorization: `bearer ${jwt}`,
              ...this.securityStateService.getCintraCloudApiRequestHeaders(request.headers)
            }
          });

          return next.handle(jwtRequest);
        }

        return next.handle(request);
      })
    );
  }
}
